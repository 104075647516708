<template>
  <v-list-item :key="item.name" class="px-1">
    <v-badge v-if="false" bordered bottom color="green" dot offset-x="22" offset-y="26">
      <v-list-item-avatar>
        <v-img :src="item.avatar"></v-img>
      </v-list-item-avatar>
    </v-badge>
    <template>
      <v-list-item-content :class="{ 'font-weight-bold': item.count_messages_unviewed }" style="box-sizing: content-box">
        <div style="display: flex; justify-content: space-between" class="d-flex list-item-message">
          <div style="font-size: 15px" class="list-item-message">{{ chat_name }}</div>
          <div style="font-size: 11px" :class="{ 'unviewed-group': item.count_messages_unviewed }">{{ getDate(item.last_message_createdon) }}</div>
        </div>
        <div v-if="chat_name" style="margin-top: 2px; justify-content: space-between; color: green" class="d-flex list-item-message">
          <div class="list-item-message" style="font-size: 12px">{{ item.data.user.name }}</div>
        </div>
        <div style="margin-top: 2px; justify-content: space-between" class="d-flex list-item-message">
          <div class="list-item-message" style="font-size: 12px">
            <span>{{ item.last_message_message }}</span>
          </div>
          <span class="list-item-count-unviewed" v-if="item.count_messages_unviewed" :value="item.count_messages_unviewed" :content="1">
            {{ item.count_messages_unviewed }}
          </span>
        </div>
      </v-list-item-content>
    </template>
  </v-list-item>
</template>

<script>
export default {
  components: {},
  props: {
    item: Object,
  },
  data() {
    return {
      selected: null,
      lazy: true,
    };
  },
  computed: {
    chat_name() {
      let res = this.item?.data?.offer?.title || this.item.id;
      return res;
    },
  },
  methods: {
    getDate(date) {
      if (!date) return null;
      const d = new Date(date);
      const n = new Date();
      let res = d.date == n.date ? d.shorttime : d.toLocaleDateString("ru-RU");
      return res;
    },
  },
};
</script>
<style lang="scss" scoped>
.list-item-message {
  overflow: hidden;
  text-overflow: ellipsis;
  // text-rendering: optimizelegibility;
  white-space: nowrap;
}
.unviewed-group {
  color: #4caf50;
}
.list-item-count-unviewed {
  font-size: 0.75rem;
  border-top-left-radius: 1.1em;
  border-top-right-radius: 1.1em;
  border-bottom-right-radius: 1.1em;
  border-bottom-left-radius: 1.1em;
  background-color: #4caf50;
  padding-left: 0.4em;
  padding-right: 0.4em;
  padding-top: 0.3em;
  padding-bottom: 0.2em;
  min-width: 0.9em;
  min-height: 1em;
  color: white;
  vertical-align: top;
  text-align: center;
}
</style>
