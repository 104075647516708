import { render, staticRenderFns } from "./chatItem.vue?vue&type=template&id=d780a5c8&scoped=true&"
import script from "./chatItem.vue?vue&type=script&lang=js&"
export * from "./chatItem.vue?vue&type=script&lang=js&"
import style0 from "./chatItem.vue?vue&type=style&index=0&id=d780a5c8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d780a5c8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VBadge,VImg,VListItem,VListItemAvatar,VListItemContent})
